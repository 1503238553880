<template>
  <div>
    <div class="row">
      <div class="col-md-3">
        <div class="orderCountView">
          <img src="/assets/images/svgs/cart-green.svg" alt="" />
          <div>
            <h4>{{ orders.totalRecords }}</h4>
            <p>Total order</p>
          </div>
        </div>
      </div>
      <div class="col-md-9">
        <ViewItems
          @backtoOrders="viewItemShow = false"
          v-if="viewItemShow"
          :items="viewItem"
        />
        <TrackOrder
          v-else-if="viewStatusShow"
          @backtoOrders="
            viewStatusShow = false;
            viewStatus = {};
          "
          :order="viewStatus"
        />
        <div v-else>
          <table class="orderTable">
            <tr>
              <th>Date</th>
              <th>Quantity</th>
              <th>Total Price</th>
              <th>Order status</th>
              <th>Delivery address</th>
              <th></th>
            </tr>
            <tr
              v-if="!loading"
              v-for="(order, index) in orders.result"
              :key="index"
            >
              <td>{{ $helpers.formatTime(order.created_at) }}</td>
              <td>
                <p @click="previewItems(order)" class="text-primary itemView">
                  {{ order.items.length }} item
                </p>
              </td>
              <td>{{ $helpers.formatPrice(order.total_price) }}</td>
              <td class="text-capitalize">
                <span class="text-primary itemView" @click="openStatus(order)">
                  Track Order
                </span>
              </td>
              <td>
                <p>
                  {{ order.shipping_address.street }},
                  {{ order.shipping_address.lga }}
                  {{ order.shipping_address.state }}
                </p>
              </td>
              <td>
                <button
                  @click.prevent="showConfirmation(order)"
                  class="cancelBtn"
                  v-if="order.status === 'placed'"
                >
                  Request Cancellation
                </button>
              </td>
            </tr>
          </table>
          <Loading v-if="loading" />
          <div class="text-center pb-5">
            <Pagination
              @pageChange="handlePageChange"
              :totalRecord="pagination.totalRecords"
              :currentPage="pagination.page"
              :perPage="pagination.per_page"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-if="showModal" class="cancelModal">
      <Loading v-if="modalLoading" />
      <div class="mod-cont" v-else>
        <p>Are you sure you want to cancel this order?</p>
        <p class="cl">
          Click
          <a
            href="https://www.imperiumng.com/terms#return-policy"
            target="_blank"
            >here</a
          >
          to learn more about our return policy
        </p>
        <div class="btns">
          <button @click.prevent="requestCancellation" class="yes">Yes</button>
          <button @click.prevent="stopCancellation" class="no">No</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Pagination from "@/components/Pagination.vue";
import Loading from "@/components/Loading.vue";
import ViewItems from "@/components/ViewItems.vue";
import TrackOrder from "@/components/TrackOrder.vue";

export default {
  components: {
    Pagination,
    Loading,
    ViewItems,
    TrackOrder,
  },
  data() {
    return {
      loading: true,
      current_page: 1,
      moreLoading: false,
      orders: {},
      viewItemShow: false,
      viewItem: [],
      viewStatusShow: false,
      viewStatus: {},
      pagination: {},
      cancelOrder: {},
      showModal: false,
      modalLoading: false,
    };
  },
  methods: {
    handlePageChange(page) {
      this.loading = true;
      this.current_page = page;
    },
  },
  watch: {
    current_page: function () {
      this.getProductsbyCategory(true);
    },
  },
  mounted() {
    this.getBl();
  },
  methods: {
    stopCancellation() {
      this.cancelOrder = {};
      this.showModal = false;
    },
    showConfirmation(order) {
      if (order.status === "placed") {
        // console.log(order);
        this.cancelOrder = order;
        this.showModal = true;
      }
    },
    requestCancellation() {
      if (this.cancelOrder.status === "placed") {
        // console.log(this.cancelOrder);
        // this.$toast.info(
        //   "Profile data",
        //   "Requesting Cancellation",
        //   this.$toastPosition
        // );
        this.modalLoading = true;

        // this.loading = true
        let data = {
          status: "cancelled",
        };
        data = this.$helpers.encrypt(JSON.stringify(data));

        data = {
          data,
        };

        let payload = {
          data,
          path: `order/${this.cancelOrder.id}/status`,
        };
        this.$store
          .dispatch("putRequest", payload)
          .then((resp) => {
            let decr = JSON.parse(this.$helpers.decrypt(resp.data.data));
            resp.data = decr;
            this.modalLoading = false;
            this.showModal = false;
            this.cancelOrder = {};
            // console.log(resp);
            if (resp.status === 200) {
              this.$toast.success(
                "Cancel Order",
                "Order has been cancelled",
                this.$toastPosition
              );
              window.scrollTo(0, 0);
              this.getBl();
            } else {
              
              this.$toast.error(
                "Cancel Order",
                "Something went wrong",
                this.$toastPosition
              );
            }
          })
          .catch((err) => {
            this.modalLoading = false;
            this.showModal = false;
            this.cancelOrder = {};
            if (err.response) {
              this.$toast.info(
                "Cancel Order",
                err.response.data.message,
                this.$toastPosition
              );
            } else {
              this.$toast.info(
                "Cancel Order",
                "Something went wrong",
                this.$toastPosition
              );
            }
            this.loading = false;
          });
      } else {
        this.$router.push("/");
      }
    },
    previewItems(order) {
      this.viewItemShow = true;
      this.viewItem = order.items;
    },
    openStatus(order) {
      this.viewStatusShow = true;
      this.viewStatus = order;
    },
    getBl() {
      this.loading = true;
      this.getCustomerOrder(false);
    },
    getCustomerOrder(divLoad) {
      this.loading = true;

      if (divLoad) {
        this.moreLoading = true;
      }

      let payload = {
        path: `order?page=${this.current_page}&per_page=20`,
      };
      this.$store
        .dispatch("getRequest", payload)
        .then((resp) => {
          let decr = JSON.parse(this.$helpers.decrypt(resp.data.data));
          resp.data = decr;
          this.loading = false;
          let { orders } = resp.data.data;
          // console.log(orders);
          this.pagination = resp.data.data.orders;
          this.orders = orders;
        })
        .catch((err) => {
          if (err.response) {
            let decr = JSON.parse(
              this.$helpers.decrypt(err.response.data.data)
            );
            err.response.data = decr;
            this.$toast.info(
              "Profile data",
              err.response.data.message,
              this.$toastPosition
            );
          } else {
            this.$toast.info(
              "Profile data",
              "Unable to load Profile data",
              this.$toastPosition
            );
          }
          this.loading = false;
        });
    },
    handlePageChange(page) {
      this.loading = true;
      this.current_page = page;
    },
  },
};
</script>
<style lang="scss" scoped>
.orderCountView {
  background: #f1f1f1;
  border-radius: 20px;
  padding: 13px;
  display: flex;
  div {
    padding-left: 10px;
    h4 {
      font-weight: bold;
      color: #255e13;
      margin: 0px;
    }
    p {
      font-size: 14px;
      margin: 0px;
      color: #121113;
    }
  }
}
.orderTable {
  width: 100%;
  tr {
    th {
      padding: 20px;
      border-bottom: 2px solid #00000030;
    }
    td {
      padding: 20px;
      border-bottom: 1px solid #00000030;
      .itemView {
        margin: 0px;
        text-decoration: underline;
        cursor: pointer;
      }
      .cancelBtn {
        background-color: transparent;
        border: none;
        color: red;
        text-decoration: underline;
        outline: none;
      }
    }
  }
}
div.cancelModal {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  backdrop-filter: blur(8px);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00000088;
  z-index: 99999999999;
  div.mod-cont {
    padding: 24px 32px;
    background-color: #fff;
    border-radius: 14px;
    p {
      font-size: 1rem;
      font-weight: 500;
    }
    .cl {
      font-size: 0.8rem;
    }
    .btns {
      display: flex;
      align-items: center;
      justify-content: space-between;

      button {
        width: 48%;
        border: none;
        font-weight: 500;
        border-radius: 4px;
        padding: 6px 0;
        color: #fff;
      }
      .yes {
        background-color: #741717;
      }
      .no {
        background-color: #255e13;
      }
    }
  }
}
</style>
