<template>
  <div>
    <p @click="$emit('backtoOrders')" class="backArrow text-primary">
      ← Back to all orders
    </p>
    <div class="row">
      <!-- <div class="col-md-4" v-for="(product, index) in order" :key="index">
        <div class="productDivView">
          <router-link :to="`/product/${product.slug}`">
            <div class="productDiv mb-5">
              <div class="image-view">
                <img :src="product.display_image" alt="" />
              </div>

              <h4 class="name f-16">{{ product.name }}</h4>
              <p class="price f-bold">
                {{ $helpers.formatPrice(product.price) }} X
                {{ product.quantity }}
              </p>
            </div>
          </router-link>
        </div>
      </div> -->
      <div class="col-12 col-md-5">
        <div class="wrrapp">
          <div :class="['prog ', level === 1 ? ' current ' : '']">
            <div class="stt">
              <div class="st">Placed</div>
              <div class="date">
                {{ $helpers.formatTime(order.created_at) }}
              </div>
            </div>
          </div>
          <div
            v-if="level >= 1"
            :class="[
              'prog ',
              level === 2 ? ' current ' : '',
              level === 1 ? ' nosh ' : '',
            ]"
          >
            <div class="stt">
              <div class="st">Shipped</div>
              <div class="date" v-if="level === 2">
                {{ $helpers.formatTime(order.updated_at) }}
              </div>
            </div>
          </div>
          <div v-if="level >= 2" :class="['prog ', level >= 2 ? ' nosh ' : '']">
            <div class="stt">
              <div class="st">{{ order.status === 'shipped' ? 'Delivered' : $helpers.capitalizeFirstLetter(order.status) }}</div>
              <div class="date" v-if="level === 3">
                {{ $helpers.formatTime(order.updated_at) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import ProductCard from "@/components/ProductCard.vue";

export default {
  props: ["order"],
  components: {
    // ProductCard,
  },
  data() {
    return {
      level: 0,
    };
  },
  mounted() {
    // console.log(this.order);
    if (this.order.status === "placed") {
      this.level = 1;
    } else if (this.order.status === "shipped") {
      this.level = 2;
    } else if (
      this.order.status === "cancelled" ||
      this.order.status === "delivered"
    ) {
      this.level = 3;
    }
  },
};
</script>
<style lang="scss" scoped>
.backArrow {
  text-decoration: underline;
  cursor: pointer;
}
.wrrapp * {
  margin: 0;
  padding: 0;
}
.prog {
  border: none;
  border-left: 4px dashed #255e1388;
  padding: 16px;
  position: relative;
  margin-block: 10px;
}
.prog::before {
  content: "";
  position: absolute;
  top: -12px;
  left: -13px;
  height: 20px;
  width: 20px;
  background-color: #255e13;
  border-radius: 50%;
}
.prog.nosh::before,
.prog.current::before {
  content: "";
  position: absolute;
  top: -12px;
  left: -9px;
  height: 20px;
  width: 20px;
  background-color: #255e13;
  border-radius: 50%;
}
.prog.nosh {
  border-left: none;
}
.prog.current {
  border-left: none;
  position: relative;
  /* overflow: hidden; */
}
.stt {
  background: #f1f1f1;
  border-radius: 20px;
  padding: 13px;
  /* display: flex; */
}
@keyframes mymove {
  0% {
    top: 0px;
    opacity: 0;
  }
  50% {
    /* top: 50%; */
    opacity: 1;
  }
  100% {
    top: calc(100% - 10px);
    opacity: 0;
  }
}
.prog.current::after {
  content: "";
  opacity: 0;
  border-left: 4px solid #255e13;
  height: 10px;
  position: absolute;
  top: 0;
  left: 0;
  /* z-index: 99999; */
  animation: mymove 1.8s infinite;
}
</style>
